import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';  // Brand icon
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';  // Brand icon

const Footer = () => {
  return (
    <footer className="d-flex flex-wrap justify-content-center align-items-center py-3 mt-4 border-top">
        <div className="col-12 d-flex align-items-center justify-content-center">
            <span className="mb-md-0 text-muted">Created by Peter Pfisterer</span>
        </div>
        <div className="col-12 mt-md-2 d-flex align-items-center justify-content-center">
          <a href="https://github.com/peterpfisterer" target="_blank" rel="noreferrer" className="footer-icon">
            <FontAwesomeIcon icon={faGithub} size="2x"/>
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a href="https://www.linkedin.com/in/peter-pfisterer-880208226/" target="_blank" rel="noreferrer" className="footer-icon">
            <FontAwesomeIcon icon={faLinkedin} size="2x"/>
          </a>
        </div>
    </footer>
  );
};

export default Footer