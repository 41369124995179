import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-12">
          <h1 className="display-3">Welcome to My Developer Portfolio!</h1>
          <p className="lead">
            Hi, I'm Peter, a full stack engineer with a passion for building dynamic, scalable web applications and cloud-based solutions. 
            I specialize in crafting end-to-end software experiences, from frontend design to backend logic, 
            with a strong emphasis on AWS cloud services for high-performance, secure, and efficient deployments.
          </p>
          <p className='lead'>
            I have hands-on experience in:
          </p>
          <ul>
            <li className='lead'>
              <strong>Frontend Technologies</strong>: React, React Native, Angular, JavaScript, TypeScript, HTML, CSS
            </li>
            <li className='lead'>
              <strong>Backend Development</strong>: Python, C#, .NET, REST APIs
            </li>
            <li className='lead'>
              <strong>Cloud Services</strong>: AWS Lambda, S3, Glue, Athena, CloudWatch
            </li>
            <li className='lead'>
              <strong>DevOps</strong>: CI/CD pipelines, Azure DevOps, Terraform
            </li>
          </ul>
          <p className='lead'>With a focus on automation, I like to write readable, concise, and secure code and develop solutions that are simple and impactful.</p>
        </div>

        <div className="col-12 my-5">
          <h1 className="display-4">My Specialties</h1>
          <ul>
            <li className='lead'>
              <strong>Full Stack Development</strong>: I bring ideas to life by building robust, scalable, and maintainable applications from the ground up.
            </li>
            <li className='lead'>
              <strong>Cloud Architecture</strong>: I specialize in designing serverless and microservice architectures using AWS, ensuring cost-efficiency and scalability.
            </li>
            <li className='lead'>
              <strong>Data Processing Solutions</strong>: Whether it's real-time data pipelines or batch data transformations, I create solutions that help businesses leverage data more effectively.
            </li>
          </ul>
        </div>

        <div className="col-12 my-5">
          <h1 className="display-4">What I've Been Working On</h1>
          <p className='lead'>I am currently working at Jack Henry in the Payments Shared Services organization. Here we specialize in providing diverse solutions for all of Payments. These are some of the projects that I have worked on since starting in June 2023:</p>
          <ul>
            <li className='lead'>
              <strong>Developer Experience</strong>: Developer Experience is a project that aims to improve the developer experience for all of Payments. 
              It provides a developer portal with documentation and API references for all of the services that Payments provides. 
            </li>
            <li className='lead'>
              <strong>Databroker</strong>: The Databroker initiative aims to consolidate the data from all of Payments into a single data lake. This data is then provided back to clients
              and used internally in BI tools to provide insights into how clients are using our products.
            </li>
          </ul>
          <p className='lead'>To read more about these projects, please see my <Link className="text-link" to="/Projects">Projects</Link> page.</p>
        </div>
      </div>
    </div>
  );
}

export default Home;