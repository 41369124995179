import React from 'react';

const Contact = () => {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-12 text-center">
          <h1 className="display-3">Contact</h1>
          <hr className='w-50 mx-auto'></hr>
          <p className="lead">
            Feel free to reach out to me on LinkedIn: <a className='text-link' href="https://www.linkedin.com/in/peter-pfisterer-880208226/" target="_blank" rel="noreferrer">Peter Pfisterer</a>
          </p>
          <p className='lead'>You can also take a look at my GitHub: <a className='text-link' href="https://github.com/peterpfisterer" target="_blank" rel="noreferrer">https://github.com/peterpfisterer</a></p>
        </div>
      </div>
    </div>
  );
}

export default Contact;