import React from 'react';
import csharp from '../assets/c-sharp.png'; 
import angular from '../assets/angular.png';
import typescript from '../assets/typescript.png';
import aws from '../assets/aws.png';
import glue from '../assets/glue.png';
import lambda from '../assets/lambda.png';
import s3 from '../assets/s3.png';
import athena from '../assets/athena.png';
import terraform from '../assets/terraform.png';
import python from '../assets/python.png';
import react from '../assets/react.png';
import javascript from '../assets/javascript.png';

const Projects = () => {
  return(
    <div className="container mt-5">
      <div className="row">
        <div className="col-12 my-5">
          <h1 className="display-4">Developer Experience</h1>
          <a className='lead' href="https://api.payments.jackhenry.com/developer/" target="_blank" rel="noreferrer">https://api.payments.jackhenry.com/developer/</a>
          <p className='lead'>
            Developer Experience is a developer portal used by Jack Henry's clients with the aim to provide documentation and API references for all of the services that Payments provides.
            One of our main goals for this project is to reduce the time it takes for clients to implement our services.
          </p>
          <p className='lead'>
            I contributed to the frontend of this portal by building out UI components using Angular and TypeScript. I created the UI for all of the pages, including
            the sign in pages as well.
          </p>
          <p className='lead'>
            I spent some time working on the .NET backend as well. I helped to create and maintain the REST APIs used to display relevant products and projects for users and 
            organizations. There is also a behind the scenes support portal that Jack Henry uses to manage organizations and their users, as well as which products they have access to.
          </p>
          <div class="row">
            <div class="col-3 col-md-1">
              <img src={csharp} width={64} alt="csharp" />
            </div>
            <div class="col-3 col-md-1">
              <img src={angular} width={64} alt="angular" />
            </div>
            <div class="col-3 col-md-1">
              <img src={typescript} width={64} alt="typescript" />
            </div>
          </div>
        </div>

        <div className='col-12 my-5'>
          <h1 className='display-4'>Databroker</h1>
          <p className='lead'>
            The Databroker initiative aims to consolidate the data from all of Payments into a single data lake. This data is then provided back to clients
            and used internally in BI tools to provide insights into how clients are using our products.
          </p>
          <p className='lead'>
            I am the primary engineer on this project. I created the AWS resources, including S3, Lambda, Glue, and Athena, to process and store the data. I also created the Terraform 
            scripts used to deploy these resources. I wrote the Python code for the Glue jobs that process/format the data and load it into the data lake.
          </p>
          <div class="row">
            <div class="col-3 col-md-1">
              <img src={aws} width={64} alt="aws" />
            </div>
            <div class="col-3 col-md-1">
              <img src={s3} width={64} alt="s3" />
            </div>
            <div class="col-3 col-md-1">
              <img src={lambda} width={64} alt="lambda" />
            </div>
            <div class="col-3 col-md-1">
              <img src={glue} width={64} alt="glue" />
            </div>
            <div class="col-3 col-md-1">
              <img src={athena} width={64} alt="athena" />
            </div>
            <div class="col-3 col-md-1">
              <img src={terraform} width={64} alt="terraform" />
            </div>
            <div class="col-3 col-md-1">
              <img src={python} width={64} alt="python" />
            </div>
          </div>
        </div>

        <div className='col-12 my-5'>
          <h1 className='display-4'>AWS CCP Exam Prep Practice</h1>
          <a className='lead' href="http://aws-ccp-prep.s3-website-us-east-1.amazonaws.com/" target="_blank" rel="noreferrer">http://aws-ccp-prep.s3-website-us-east-1.amazonaws.com/</a>
          <a className='lead' href="https://github.com/peterpfisterer/AWS-CCP-Exam-Practice" target="_blank" rel="noreferrer">https://github.com/peterpfisterer/AWS-CCP-Exam-Practice</a>
          <p className='lead'>
            While working on Databroker and studying for the AWS Certified Cloud Practitioner exam, I noticed that there were few free resources available to practice for the exam. 
            This inspired me to create a simple web application that generates practice tests. The application is built using React and JavaScript and is hosted on AWS S3.
          </p>
          <p className='lead'>
            The data for the practice test questions is hundreds of questions in markdown. I created a Python script that reads the markdown files and parses each question into JSON to be used 
            by the React application.
          </p>
          <div class="row">
            <div class="col-3 col-md-1">
              <img src={javascript} width={64} alt="javascript" />
            </div>
            <div class="col-3 col-md-1">
              <img src={react} width={64} alt="react" />
            </div>
            <div class="col-3 col-md-1">
              <img src={python} width={64} alt="python" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
